$image-preview-max-width: 300px;
$primary-color: #CA7F5A;
$hover-color: #b96941;
$font-family: 'Helvetica Neue', Arial, sans-serif;
$background-gradient: linear-gradient(135deg, #faf8f5 0%, #e2d2c3 100%);
$box-shadow-main: 0 10px 20px rgba(0, 0, 0, 0.2);
$box-shadow-hover: 0 8px 16px rgba(0, 0, 0, 0.2);

.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-family;
  padding: 40px;
  background: $background-gradient;
  border-radius: 15px;
  box-shadow: $box-shadow-main;
  max-width: 500px;
  margin: auto;
  animation: fadeIn 0.5s ease-in-out;

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;

    input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }

    .custom-file-upload {
      border: 2px solid $primary-color;
      color: white;
      background-color: $primary-color;
      padding: 12px 30px;
      border-radius: 50px;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      display: inline-block;
      transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;

      &:hover {
        background-color: $hover-color;
        transform: scale(1.05);
        box-shadow: $box-shadow-hover;
      }
    }
  }

  .image-preview-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-preview {
      max-width: $image-preview-max-width;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: $box-shadow-main;
      transition: transform 0.3s, box-shadow 0.3s;

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      &:hover {
        transform: scale(1.02);
        box-shadow: $box-shadow-hover;
      }
    }

    .image-info {
      margin-top: 10px;
      text-align: center;

      .image-name, .image-size {
        font-size: 16px;
        color: #555;
        animation: fadeInUp 0.5s ease-in-out;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
